<template>
  <div class="basic">
    <div class="_title" v-if="title">基本资料</div>
    <div class="_content" :class="className">
      <v-card flat class="pb-12" min-height="500">
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="valid" lazy-validation :style="formStyle">
            <div class="d-flex justify-center align-center">
              <div class="label mr-6 required">用户账号:</div>
              <v-text-field
                disabled
                v-model="user.email"
                :rules="rules.emailRules"
                required
              />
            </div>

            <div class="d-flex justify-center align-center">
              <div class="label required mr-6">姓名:</div>
              <v-text-field
                required
                v-model="user.realName"
                :rules="rules.realName"
              />
            </div>
            <div class="d-flex align-center">
              <div class="label required mr-6">性别:</div>
              <v-radio-group v-model="user.gender" row>
                <v-radio label="男" :value="1"></v-radio>
                <v-radio label="女" :value="0"></v-radio>
              </v-radio-group>
            </div>
            <div class="d-flex justify-center align-center">
              <div class="label required mr-6">单位:</div>
              <v-text-field
                v-model="user.organization"
                :rules="rules.organization"
                required
              />
            </div>
            <!-- <div class="d-flex justify-center align-center">
              <div class="label required mr-6">职务:</div>
              <v-text-field
                v-model="user.position"
                :rules="rules.position"
                required
              />
            </div> -->
            <div class="d-flex justify-center align-center">
              <div class="label required mr-6">手机:</div>
              <v-text-field
                v-model="user.phone"
                :rules="rules.phone"
                required
              />
            </div>
            <!-- <div class="d-flex justify-center align-center">
              <div class="label mr-6">座机电话:</div>
              座机号码非比天项目
             <v-text-field v-model="user.call" :rules="rules.call" required />
                  <v-text-field v-model="user.call" required="false"/>
            </div> -->
            <div class="d-flex align-center">
              <div class="label required mr-6">参会身份:</div>
              <v-radio-group v-model="user.identity" row>
                <v-radio label="在职代表" :value="1"></v-radio>
                <v-radio label="在校学生" :value="2"></v-radio>
              </v-radio-group>
            </div>
            <v-btn
              style="min-width: 0 !important; width: 300px; margin: 0 auto;"
              :disabled="!valid"
              color="success"
              block
              class="mt-4"
              @click="userAction"
            >
              确定
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,

      rules: {
        realName: [(v) => !!v || '请输入姓名'],
        organization: [(v) => !!v || '请输入单位'],
        position: [(v) => !!v || '请输入职位'],
        phone: [
          (v) => !!v || '请输入手机',
          (v) =>
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
              v
            ) || '手机号不正确',
        ],
        call: [(v) => !!v || '请输入座机电话'],
      },
      user: {
        email: '',
        realName: '',

        organization: '',
        position: '',
        phone: '',
        call: '',
      },
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width: 100%; margin: auto'
        case 'sm':
          return 'width: 50%; margin: auto'
        case 'md':
          return 'width: 60%; margin: auto'
        case 'lg':
          return 'width: 40%; margin: auto'
        case 'xl':
          return 'width: 40%; margin: auto'
      }
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: 'tab-item',
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$request({
        url: '/user/get',
        method: 'get',
      }).then((res) => {
        this.user = res.data
      })
    },
    userAction() {
      this.$request({
        url: '/user/update',
        method: 'post',
        data: this.user,
      }).then(() => {
        this.$message.tips('修改成功')
        this.getUser()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.basic {
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: '*';
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  padding: 0 10px;
  padding-bottom: 150px;
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
